import {
  operations,
  paths
} from 'driverama-core/api/driverama/generated/orders'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { UseQueryOptions, useQuery } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { URLS } from '../../../constants/api'
import { QUERY_KEYS } from '../../../constants/queryKeys'

export type OrderSearchResponse = paths['/admin/orders/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']

type OrdersSearchRequest = Pick<
  operations['searchDeliveryAppointments']['requestBody']['content']['application/json'],
  'userIds' | 'orderStates' | 'ids'
>

export type OrderSearch = OrderSearchResponse['content'][number]
export type OrderDeliveryState = OrderSearch['state']

type QueryOpts = UseQueryOptions<unknown, unknown, OrderSearchResponse>

export async function fetchOrdersSearch(body: OrdersSearchRequest) {
  const res = await apiAuthFetcher<OrderSearchResponse>(URLS.ordersSearch, {
    method: HTTPMethod.POST,
    body
  })

  return res.json
}

export function useOrdersSearchQuery(
  body: OrdersSearchRequest,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.ordersSearch(body),
    async () => fetchOrdersSearch(body),
    opts
  )
}

export function getOrder(orders: OrderSearchResponse, orderId: string) {
  return orders.content.find(x => x.id === orderId)
}

export function getOrderDeliveryType(
  deliveryAppointment?: OrderSearch['deliveryAppointment']
) {
  if (deliveryAppointment?.branchId) {
    return 'BRANCH'
  }

  return 'HOME'
}
